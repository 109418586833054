import styled, { css } from 'styled-components'
import { TextStyles } from '@/constants'
import Button from '@/components/Button'
import Typography from '@/components/Typography'
import { styledText } from '@/components/Typography/Typography.styles'
import ArrowForward from '../../../../../../public/images/icons/arrow-forward.svg'

export const TabContent = styled.div`
  margin-top: 8px;
  padding-left: 56px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-left: 68px;
  }
`

export const TabItemWrapper = styled.div<{
  $isActivated: boolean
  backgroundColor?: string
}>`
  cursor: pointer;
  padding: 16px;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black05};
  transition: max-height 0.5s ease-in-out;
  max-height: 72px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 24px 16px;
    max-height: 100px;
  }

  ${TabContent} {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  ${({ $isActivated, backgroundColor }) => $isActivated && css`
    cursor: unset;
    border-radius: 4px;
    background-color: ${backgroundColor};
    padding: 16px !important;
    max-height: 168px;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      max-height: 180px;
    }

    ${TabContent} {
      opacity: 1;
    }
  `}
`

export const TabIcon = styled.img``

export const TabHeader = styled.div`
  display: flex;
  align-items: center;

  ${TabIcon}, .dotlottie-container {
    width: 40px;
    height: 40px;
    display: block;

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      width: 52px;
      height: 52px;
    }
  }
`

export const TabLabel = styled.div`
  ${styledText[TextStyles['Heading 3']]}
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.black01};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 2']]}
  }
`

export const ArrowForwardIcon = styled(ArrowForward)`
  margin-left: 8px;

  &, path {
    fill: ${({ theme }) => theme.colors.black01};
  }
`

export const Description = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.black02};
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

// NEW-BUTTON: Text - Product Experience - Product Suite Tab Item
export const CustomTextButton = styled(Button).attrs({
  variant: 'link',
  icon: ArrowForward,
})`
  position: relative;
  padding: 4px 0;

  // Custom for only Text button
  padding: 4px 0 3px 0;
  border-radius: 0 !important;
  border-bottom: 2px solid transparent;
  color: ${({ theme }) => theme.colors.darkBlue};

  & > span > svg,
  & > svg {
    &, & path {
      fill: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  :not([disabled]):hover {
    color: ${({ theme }) => theme.colors.ppBlueHover} !important;
    border-color: ${({ theme }) => theme.colors.ppBlueHover} !important;

    & > span > svg,
    & > svg {
      &, & path {
        fill: ${({ theme }) => theme.colors.ppBlueHover} !important;
      }
    }
  }
`
